import React from 'react';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Squiggle from '@images/svgs/squiggle.svg';
import TeamCard from '@components/TeamCard';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import Button from '@components/Button';

const Contact = ({
    data: {
        page: {
            frontmatter: {
                title,
                description,
                body,
                graphic: { publicURL: graphic },
                stages,
            },
        },
        team: { edges: teamData },
    },
}) => {
    const team = teamData
        .map(member => member.node.frontmatter)
        ?.sort((a, b) => (!a?.priority || !b?.priority ? 1 : a?.priority > b?.priority ? 1 : -1));

    return (
        <Layout>
            <SEO title="About" />
            <Hero
                title={title}
                description={description}
                image={graphic}
                imageWrapperClassName="-ml-12"
            />

            <div className="max-w-6xl px-5 py-20 xl:px-0 mx-auto">
                <div className={`${styles.teamBody} md:w-2/3 lg:w-3/5 px-3 m-auto`}>
                    <div className="mb-4">
                        <Squiggle style={{ maxWidth: 50 }} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </div>
            </div>

            <div className={`${styles.teamMembers}`}>
                <div className="max-w-6xl px-5 py-20 xl:px-0 mx-auto">
                    <h2>Our core team</h2>
                    <div className="mt-8 flex flex-wrap -mr-4 justify-center">
                        {team?.map(props => (
                            <div className="w-full md:w-1/3 mb-4 pr-4">
                                <TeamCard key={props.name} {...props} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={`${styles.stages} max-w-6xl container px-5 xl:px-0 mx-auto`}>
                <div className="pt-24">
                    <h2>Our process</h2>
                </div>
                {stages?.map(({ title, body, graphics }, index) => (
                    <div
                        className={`${
                            styles.stagesStage
                        } py-12 md:py-24 flex flex-col md:flex-row items-center ${
                            index % 2 !== 0 ? 'md:flex-row-reverse' : ''
                        }`}
                    >
                        <div
                            className={`w-full md:w-1/2 ${index % 2 === 0 ? 'md:pr-6' : 'md:pl-6'}`}
                        >
                            <h3>{title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                        </div>
                        {!!graphics && (
                            <div
                                className={`w-full md:w-1/2 flex items-center justify-center ${
                                    index % 2 !== 0 ? 'md:pr-6' : 'md:pl-6'
                                }`}
                            >
                                <div className="w-full md:w-4/5 flex items-center justify-center flex-wrap">
                                    {graphics?.map(({ image, name, link }) => (
                                        <a
                                            rel={name}
                                            href={link}
                                            className={`${styles.stagesStageGraphic}`}
                                        >
                                            <Img alt={name} fluid={image?.childImageSharp?.fluid} />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <hr />
            <div className="bg-white">
                <div className="max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center">
                    <h2 className="my-2">How can we help?</h2>
                    <p className="text-xl mt-2 font-medium  mx-auto max-w-4xl">
                        It might be easier than you think. Get in touch today.
                    </p>
                    <div className="mt-8 flex flex-col lg:flex-row justify-center items-center ">
                        <Link to="tel:01206259355">
                            <Button text="Phone us" />
                        </Link>
                        <Button
                            secondary
                            onClick={() => window?.FB?.CustomerChat?.show(true)}
                            text="Open chat"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query($path: String) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: about_title
                description: about_description
                body: about_body
                graphic: about_graphic {
                    publicURL
                }
                stages: about_stages {
                    title: about_stages_title
                    body: about_stages_body
                    graphics: about_stages_graphics {
                        image: about_stages_graphics_image {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        name: about_stages_graphics_name
                        link: about_stages_graphics_link
                    }
                }
            }
        }
        team: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/team/" } }) {
            edges {
                node {
                    frontmatter {
                        name: team_name
                        role: team_role
                        description: team_description
                        image: team_image {
                            childImageSharp {
                                fluid(maxWidth: 787) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        priority: team_priority
                    }
                }
            }
        }
    }
`;

export default Contact;
